<template>
    <vision-module-component/>
</template>

<script>
    import VisionModuleComponent from "@/components/admin/VisionModuleComponent";
    
    export default {
        name: "VisionModule",
        title: "Visión | Private",
        components: {VisionModuleComponent}
    }
</script>

<style scoped>

</style>